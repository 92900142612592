import React, { useEffect, useState } from 'react';
import {
    VStack,
    Center,
    Heading,
    useToast,
    Text,
    Button
} from '@chakra-ui/react'
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import apiConfig from '../config/apiconfig';

function useQuery() {
	const { search } = useLocation();  
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ResultDetails = () => {
    const [first, setFirst] = useState(true);
    const [points, setPoints] = useState();
    const [round, setRound] = useState(0);

    let query = useQuery();
    const navigate = useNavigate();
    const toast = useToast();

    var participantId = query.get("id");
    useEffect(() => {
        if(participantId==null){
            navigate('/');
        }
    });

    const doFetch = () => {
        axios.get(apiConfig.webApi + 'points/' + participantId)
        .then((response) => {
            setPoints(response.data);
            console.log(response.data);
        })
        .catch(() => toast({ title: 'Error while getting points\' details', status: 'error', duration: 2000, isClosable: true}));

        axios.get(apiConfig.webApi + 'game/' + participantId + '/round')
        .then((response) => {
            setRound(response.data);
            console.log(response.data);
        })
        .catch(() => toast({ title: 'Error while getting the round', status: 'error', duration: 2000, isClosable: true}));
    };

    if(first){
        setFirst(false);
        doFetch();
    }

    return(
        <Center>
			{points && <VStack my="5" mx="5" fontSize='xl' width="4xl">
                {points.map(item => <>
                    <Heading>Round #{item.round}</Heading>
                    {item.points.map(item => <>
                        <Text>{item.name}: {item.point}</Text>
                    </>)}
                </>)}
                
                <Button fontSize={18} my='4' colorScheme='green' p="4" alignSelf="center" onClick={() => round === 4 ? navigate('/results?id=' + participantId) : navigate('/game?id=' + participantId)}>Continue</Button>
            </VStack>}
        </Center>
    );
};

export default ResultDetails;
