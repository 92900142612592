import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Flex,
    Popover,
    PopoverContent,
    PopoverTrigger,
    PopoverBody,
    IconButton
} from '@chakra-ui/react'
import { InfoOutlineIcon } from '@chakra-ui/icons'


const PlayerTableTop = ({player}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    var tt = player.tableTop.sort((a, b) => a.card.name.localeCompare(b.card.name));

  return (
    <>
      <Button m='1' onClick={onOpen} colorScheme='blue' variant='outline'>
        {player.name}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="90%">
          <ModalHeader>{player.name}'s chosen cards</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Flex w='90%' wrap="wrap" justifyContent='center'>
                {tt && tt.map(item => <Flex bg="lightgray" m='4' p='1' borderRadius="10" alignItems="center"><Button mr='1' colorScheme={item.card.colorScheme}>{item.card.name}</Button><Popover><PopoverTrigger><IconButton bg='lightgray'><InfoOutlineIcon /></IconButton></PopoverTrigger><PopoverContent borderRadius="10" border="1px solid black" w='fit-content' maxW='300px'><PopoverBody fontSize='small'>{item.card.description}</PopoverBody></PopoverContent></Popover></Flex>)}
          </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
};

export default PlayerTableTop;