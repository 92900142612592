import React from 'react';
import {
  ChakraProvider,
} from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home';
import WaitingRoom from './components/waitingroom';
import Game from './components/game';
import Results from './components/results';
import ResultDetails from './components/resultDetails';

function App() {
  return (
    <ChakraProvider>
      <Router>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/waitingroom' element={<WaitingRoom />} />
            <Route path='/game' element={<Game />} />
            <Route path='/results' element={<Results />} />
            <Route path='/points' element={<ResultDetails />} />
          </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
