import React, { useState, useEffect } from 'react';
import {
	VStack,
	Heading,
	Center,
    Text,
    Button,
    Image,
    useToast
} from '@chakra-ui/react'
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import apiConfig from '../config/apiconfig';
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr';

function useQuery() {
	const { search } = useLocation();
  
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const WaitingRoom = () => {
    const [canStart, setCanStart] = useState(false);
    const [code, setCode] = useState();
    const [first, setFirst] = useState(true);
    const [participants, setParticipants] = useState([]);
    const [connection, setConnection] = useState(null);

	const navigate = useNavigate();
    let query = useQuery();
    const toast = useToast();

    const handleStart = () => {
        axios.get(apiConfig.webApi + 'game/' + participantId + '/start');
    };

    const doFetch = () => {
        axios.get(apiConfig.webApi + 'game/' + participantId + '/participants')
            .then((response) => {
                setParticipants(response.data);
                console.log(response.data);
                if(response.data.length > 1){
                    setCanStart(true);
                }
            })
            .catch(() => toast({ title: 'Error while requesting other participants', status: 'error', duration: 2000, isClosable: true}));
        axios.get(apiConfig.webApi + 'game/' + participantId + '/code')
            .then((response) => {
                setCode(response.data);
                console.log(response.data);
            })
            .catch(() => toast({ title: 'Error while requesting game code', status: 'error', duration: 2000, isClosable: true}));
    };

    var participantId = query.get("id");
    var myGame = query.get("my");
    
    useEffect(() => {
        if(participantId==null){
            navigate('/');
        }
    });

    useEffect(() => {
        const connect = new HubConnectionBuilder()
            .configureLogging(LogLevel.Debug)
            .withUrl(apiConfig.hub, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .build();

        setConnection(connect);
      }, []);
    
      useEffect(() => {
        if (connection) {
          connection
            .start()
            .then(() => {
              connection.on("ReceiveNewParticipant", (message) => {
                console.log(message);
                doFetch();
              });
              connection.on("ReceiveGameStart", (message) => {
                console.log(participantId);
                navigate('/game?id=' + participantId);
              });
            })
            .catch((error) => console.log(error));
        }
    //eslint-disable-next-line
      }, [connection]);

    if(first){
        setFirst(false);
        doFetch();
    }

	return (
		<Center>
			<VStack my="5" mx="5" fontSize='xl' width="4xl">
                <Image src='hourglass.gif' maxW='200px'/>
				<Heading size='2xl' textAlign="center" my='2' >
					Waiting for others to join
				</Heading>

                <Text>Code: {code}</Text>

                <Text>
                    Already joined:
                </Text>
                {participants.map(name => <Text key={name}>{name}</Text>)}

                ({canStart && myGame && <Button fontSize={18} mb='4' colorScheme='green' p="4" alignSelf="center" onClick={() => handleStart()}>Start</Button>})
            </VStack>
		</Center>
	);
};

export default WaitingRoom;
