import React, { useState, useEffect } from 'react';
import {
	VStack,
	Flex,
	Center,
    useToast,
    Text,
    Button,
    IconButton,
    Popover,
    PopoverContent,
    PopoverTrigger,
    PopoverBody,
    Heading,
    Image
} from '@chakra-ui/react'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import apiConfig from '../config/apiconfig';
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr';
import PlayerTableTop from './playerTableTop';

function useQuery() {
	const { search } = useLocation();
  
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Game = () => {
    const [deck, setDeck] = useState();
    const [myCards, setMyCards] = useState();
    const [players, setPlayers] = useState();
    const [points, setPoints] = useState(0);
    const [round, setRound] = useState(0);
    const [first, setFirst] = useState(true);
    const [picked, setPicked] = useState(0);
    const [waitingFor, setWaitingFor] = useState();
    const [connection, setConnection] = useState(null);

	const navigate = useNavigate();
    const toast = useToast();
    let query = useQuery();

    const handlePick = (relationId) => {
        setPicked(1);
        axios.get(apiConfig.webApi + 'deck/' + participantId + '/pick/' + relationId)
            .then((response) => {
                // axios.get(apiConfig.webApi + 'deck/' + participantId + '/waiting')
                //     .then((res) => {
                //         if (!res.data) {
                            setWaitingFor(response.data);
                    //     }
                    //     else {
                    //         setPicked(0);
                    //     }
                    // })
                    // .catch(() => {
                    //     setPicked(0);
                    //     toast({ title: 'Error while getting your card number', status: 'error', duration: 2000, isClosable: true})
                    // });
            })
            .catch(() => {
                setPicked(0);
                toast({ title: 'Error while picking a card', status: 'error', duration: 2000, isClosable: true})
            });
    };

    // const chopstickClick = (name) => {
    //     if (name === 'Chopsticks') {
    //         axios.get(apiConfig.webApi + 'deck/' + participantId + '/chopsticks')
    //             .then(() => {
    //                 doFetch();
    //             })
    //             .catch(() => {
    //                 toast({ title: 'Error while picking chopsticks', status: 'error', duration: 2000, isClosable: true})
    //             });
    //     }
    // };

    const doFetch = () => {
        axios.get(apiConfig.webApi + 'deck/' + participantId + '/my')
            .then((response) => {
                setDeck(response.data.sort((a, b) => a.card.name.localeCompare(b.card.name)));
            })
            .catch(() => toast({ title: 'Error while getting your deck', status: 'error', duration: 2000, isClosable: true}));
        
        axios.get(apiConfig.webApi + 'deck/' + participantId + '/myTableTop')
            .then((response) => {
                setMyCards(response.data.sort((a, b) => a.card.name.localeCompare(b.card.name)));
            })
            .catch(() => toast({ title: 'Error while getting your deck', status: 'error', duration: 2000, isClosable: true}));
            
        axios.get(apiConfig.webApi + 'deck/' + participantId + '/points')
            .then((response) => {
                setPoints(response.data);
            })
            .catch(() => toast({ title: 'Error while getting your points', status: 'error', duration: 2000, isClosable: true}));

        axios.get(apiConfig.webApi + 'game/' + participantId + '/round')
            .then((response) => {
                setRound(response.data);
            })
            .catch(() => toast({ title: 'Error while getting rounds', status: 'error', duration: 2000, isClosable: true}));
        
        axios.get(apiConfig.webApi + 'game/' + participantId + '/players')
            .then((response) => {
                setPlayers(response.data);
                console.log(response.data);
            })
            .catch(() => toast({ title: 'Error while getting other players\' details', status: 'error', duration: 2000, isClosable: true}));
            
        axios.get(apiConfig.webApi + 'deck/' + participantId + '/picked')
            .then((response) => {
                setPicked(response.data ? 1 : 0);
            })
            .catch(() => toast({ title: 'Error while getting your status', status: 'error', duration: 2000, isClosable: true}));
    };

    var participantId = query.get("id");
    
    useEffect(() => {
        if(participantId==null){
            navigate('/');
        }
    });

    useEffect(() => {
        const connect = new HubConnectionBuilder()
            .configureLogging(LogLevel.Debug)
            .withUrl(apiConfig.hub, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .build();

        setConnection(connect);
      }, []);
    
      const receiveNewDeck = () => {
        doFetch();
        setPicked(0);
        setWaitingFor("");
      };

      useEffect(() => {
        if (connection) {
          connection
            .start()
            .then(() => {
              connection.on("ReceiveSomeonePicked", (message) => {
                console.log("gotit");
                setWaitingFor(message);
              });
              connection.on("ReceiveNewDeck", () => {
                setPicked(2);
                setTimeout(receiveNewDeck, 1000);
              });
              connection.on("ReceiveEndOfGame", () => {
                navigate('/results?id=' + participantId)
              });
              connection.on("ReceiveEndOfRound", () => {
                navigate('/points?id=' + participantId)
              });
            })
            .catch((error) => console.log(error));
        }
    //eslint-disable-next-line
      }, [connection]);

    if(first){
        setFirst(false);
        doFetch();
    }

	return (
		<Center>
			<VStack my="5" mx="5" fontSize='xl' width="4xl">
                <Heading>Round#{round}</Heading>
                <Text>My points: {points}</Text>
                <Center flexDir='column' p='2' borderRadius="10" border="1px solid black">
                    <Text>Other players' chosen cards:</Text>
                    {players && <Flex w='90%' wrap="wrap" justifyContent='center'>
                        {players.map(item => <PlayerTableTop player={item} />)}
                    </Flex>}
                </Center>
                {picked === 0 && myCards && myCards.length>0 && <Center flexDir='column' p='2' borderRadius="10" border="1px solid black">
                    <Text>My chosen cards:</Text>
                    <Flex w='90%' wrap="wrap" justifyContent='center'>
                        {myCards && myCards.map(item => <Flex bg="lightgray" m='4' p='1' borderRadius="10" alignItems="center"><Button mr='1' colorScheme={item.card.colorScheme}>{item.card.name}</Button><Popover><PopoverTrigger><IconButton bg='lightgray'><InfoOutlineIcon /></IconButton></PopoverTrigger><PopoverContent borderRadius="10" border="1px solid black" w='fit-content' maxW='300px'><PopoverBody fontSize='small'>{item.card.description}</PopoverBody></PopoverContent></Popover></Flex>)}
                    </Flex>
                </Center>}
                <Center flexDir='column' p='2' borderRadius="10" border="1px solid black">
                    {picked === 2 && <Image src='loading.gif' maxW='200px'/>}
                    {picked === 1 && <VStack><Text align='center'>Waiting for: {waitingFor}</Text><Image src='hourglass.gif' maxW='200px'/></VStack> }
                    {picked === 0 && <Text>Pick a card:</Text>}
                    {picked === 0 && <Flex w='98%' wrap="wrap" justifyContent='center'>
                    {deck && deck.map(item => <Flex bg="lightgray" m='4' p='1' borderRadius="10" alignItems="center"><Button mr='1' colorScheme={item.card.colorScheme} onClick={() => handlePick(item.id)}>{item.card.name}</Button><Popover><PopoverTrigger><IconButton bg='lightgray'><InfoOutlineIcon /></IconButton></PopoverTrigger><PopoverContent borderRadius="10" border="1px solid black" w='fit-content' maxW='300px'><PopoverBody fontSize='small'>{item.card.description}</PopoverBody></PopoverContent></Popover></Flex>)}
                </Flex>}
                </Center>
            </VStack>
		</Center>
	);
};

export default Game;
