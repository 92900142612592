import React, { useEffect, useState } from 'react';
import {
    VStack,
    HStack,
    Center,
    Heading,
    useToast,
    Image,
    Text,
    Button
} from '@chakra-ui/react'
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import apiConfig from '../config/apiconfig';

function useQuery() {
	const { search } = useLocation();  
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Results = () => {
    const [first, setFirst] = useState(true);
    const [players, setPlayers] = useState();

    let query = useQuery();
    const navigate = useNavigate();
    const toast = useToast();

    var participantId = query.get("id");
    useEffect(() => {
        if(participantId==null){
            navigate('/');
        }
    });

    const doFetch = () => {
        axios.get(apiConfig.webApi + 'game/' + participantId + '/allplayers')
        .then((response) => {
            setPlayers(response.data);
            console.log(response.data);
        })
        .catch(() => toast({ title: 'Error while getting players\' details', status: 'error', duration: 2000, isClosable: true}));
    };

    if(first){
        setFirst(false);
        doFetch();
    }

    return(
        <Center>
			{players && <VStack my="5" mx="5" fontSize='xl' width="4xl">
                <Heading>Results</Heading>
                <HStack maxW='100%' mb="20">
                    <VStack mt='40px'>
                        <Image src='silver.png' maxW='80px'/>
                        <Text>{players[1].name}</Text>
                        <Text>{players[1].points} points</Text>
                    </VStack>
                    <VStack>
                        <Image src='gold.png' maxW='120px'/>
                        <Text>{players[0].name}</Text>
                        <Text>{players[0].points} points</Text>
                    </VStack>
                    <VStack mt='60px'>
                        <Image src='bronze.png' maxW='60px'/>
                        <Text>{players.length > 2 ? players[2].name : "invite more"}</Text>
                        <Text>{players.length > 2 ? players[2].points + " points" : "people"}</Text>
                    </VStack>
                </HStack>
                {players.length > 3 && <Text>4th place: {players[3].name}: {players[3].points} points</Text>}
                {players.length > 4 && <Text>5th place: {players[4].name}: {players[4].points} points</Text>}
                {players.length > 5 && <Text>6th place: {players[5].name}: {players[5].points} points</Text>}
                <Button fontSize={18} mt='4' colorScheme='green' p="4" alignSelf="center" onClick={() => navigate('/points?id=' + participantId)}>Show points</Button>
                <Button fontSize={18} colorScheme='green' p="4" alignSelf="center" onClick={() => navigate('/')}>Return to home</Button>
            </VStack>}
        </Center>
    );
};

export default Results;
