import React, { useState } from 'react';
import {
	VStack,
	Heading,
	Center,
	Button,
    NumberInput,
    NumberInputField,
    Input,
    useToast
} from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import apiConfig from '../config/apiconfig';

const Home = () => {
    const [code, setCode] = useState();
    const [name, setName] = useState("");

	const navigate = useNavigate();
    const toast = useToast();

    const handleJoin = () => {
        if(code>=10000 && code < 100000 && name){
            axios.post(apiConfig.webApi + `game/join/${code}/${name}`)
            .then((response) => {
                if(response.status===200){
                    localStorage.setItem('participantId', response.data);
                    navigate('/waitingroom?id=' + response.data);
                }else{
                    toast({ title: 'Error while joining the game', status: 'error', duration: 2000, isClosable: true});
                }
            }).catch(() => {
                toast({ title: 'Error while joining the game', status: 'error', duration: 2000, isClosable: true});
            });
        } else {
            toast({ title: 'Invalid code or name', status: 'error', duration: 2000, isClosable: true});
        }
        
    };

    const handleCreate = () => {
        if(name){
            axios.post(apiConfig.webApi + 'game/create/' + name)
            .then((response) => {
                if(response.status===200){
                    localStorage.setItem('participantId', response.data);
                    navigate('/waitingroom?id=' + response.data + '&my=true');
                }else{
                    toast({ title: 'Error while creating the game', status: 'error', duration: 2000, isClosable: true});
                }
            }).catch(() => {
                toast({ title: 'Error while creating the game', status: 'error', duration: 2000, isClosable: true});
            });
        } else {
            toast({ title: 'Invalid code or name', status: 'error', duration: 2000, isClosable: true});
        }
        
    };

	return (
		<Center>
			<VStack my="5" mx="5" fontSize='xl' width="4xl">
				<Heading size='2xl' textAlign="center" my='2' >
					Welcome to Sushi Go
				</Heading>

                <VStack border="1px solid black" mt="20" borderRadius="10">
                    <NumberInput step={1} w="100px" min={0} max={100000} bg='white' mt='4' onChange={(event) => { event && setCode(event); }} minW="150">
                        <NumberInputField placeholder='Join code' pr='16px' textAlign='center'/>
                    </NumberInput>
                    <Input w="140px" m='4' border="1px solid lightgray" placeholder='Name' textAlign='center' onChange={(event) => setName(event.target.value)}/>
                    <Button fontSize={18} mb='4' colorScheme='green' p="4" alignSelf="center" onClick={() => handleJoin()}>Join Game</Button>
                </VStack>

                <VStack border="1px solid black" mt="10" borderRadius="10">
                    <Input w="140px" m='4' border="1px solid lightgray" placeholder='Name' textAlign='center' onChange={(event) => setName(event.target.value)}/>
                    <Button fontSize={18} mb='4' colorScheme='green' p="4" alignSelf="center" onClick={() => handleCreate()}>Create Game</Button>
                </VStack>
            </VStack>
		</Center>
	);
};

export default Home;
